import { HigherApiClient } from 'src/api/client/ApiClient'
import { apiOps } from 'src/api/defs/apiOps'
import { RysAuditListItem } from 'src/api/defs/RysAuditListItem.type'
import { RysAuditFilter } from '../../api/defs/RysAuditFilter.type'
import { strIdToNum } from '../../domain/strIdToNum'
import { DayInterval } from '../../api/defs/DayInterval.type'
import { ApiPageResponse } from '../../api/collection/ApiPageRequest'

const ops = apiOps.RysAuditListItem

export type RysFilter = {
    interval: DayInterval
    warehouseId: string | null
    contractId: string | null
}

export const toApiRysAuditFilter = (filter: RysFilter): RysAuditFilter => ({
    interval: filter.interval,
    warehouseId:
        filter.warehouseId == null ? undefined : strIdToNum(filter.warehouseId),
    contractId:
        filter.contractId == null ? undefined : strIdToNum(filter.contractId)
})

export class RysAuditApi {
    constructor(private api: HigherApiClient) {}

    // list = (req: ListReq<NoListFilter>): Promise<Page<RysAuditListItem>> =>
    //     this.api.getPage<RysAuditListItem, NoListFilter>(
    //         apiOps.RysAuditListItem.list,
    //         m.RysAuditListItem,
    //         req
    //     )

    list = (filter: RysFilter): Promise<ApiPageResponse<RysAuditListItem>> =>
        this.api.do<ApiPageResponse<RysAuditListItem>>(ops.list, {
            queryParams: toApiRysAuditFilter(filter)
        })
}
