import { commonPrefix } from 'src/multirole/commonPrefix'

export const WarehousePages = {
    List: {
        path: `${commonPrefix}/warehouse`,
        comp: () => import('src/business/Warehouse/WarehouseListPage'),
        to: (): string => `${commonPrefix}/warehouse?filter.deactivated=false`
    },
    WarehouseCreate: {
        path: '/warehouse/create',
        comp: () => import('src/business/Warehouse/WarehouseCreatePage'),
        to: (): string => '/warehouse/create'
    },
    WarehouseDetail: {
        path: `${commonPrefix}/warehouse/:id`,
        comp: () => import('src/business/Warehouse/WarehouseDetailPage'),
        to: (id: string): string => `${commonPrefix}/warehouse/${id}`
    },
    BoxCreate: {
        path: `${commonPrefix}/warehouse/:id/create-box`,
        comp: () => import('src/business/Box/BoxCreatePage'),
        to: (id: string): string => `${commonPrefix}/warehouse/${id}/create-box`
    },
    WarehousePodorys: {
        path: `${commonPrefix}/warehouse/:id/podorys`,
        comp: () => import('src/business/Warehouse/WarehousePodorysPage'),
        to: (id: string): string => `${commonPrefix}/warehouse/${id}/podorys`
    },
    WarehouseImages: {
        path: `${commonPrefix}/warehouse/:id/images`,
        comp: () => import('src/business/Warehouse/WarehouseImagesPage'),
        to: (id: string): string => `${commonPrefix}/warehouse/${id}/images`
    },
    WarehousePromo: {
        path: '/warehouse/:id/promo',
        comp: () => import('src/business/Warehouse/WarehousePromoPage'),
        to: (id: string): string => `/warehouse/${id}/promo`
    },
    UploadDocument: {
        path: `${commonPrefix}/warehouse/:id/upload-document`,
        comp: () => import('src/business/Warehouse/UploadDocumentPage'),
        to: (id: string): string =>
            `${commonPrefix}/warehouse/${id}/upload-document`
    }
}
