import { setPathValues } from 'src/i18n/lib/texts/keysUtils'

export const msg = {
    Address: {
        entity: {
            attr: {
                city: '',
                countryCode: '',
                number: '',
                street: '',
                zip: ''
            },
            name: ''
        }
    },
    // WEB
    ui: {
        document: {
            export: '',
            download: ''
        },
        nav: {
            items: {
                login: '',
                logout: ''
            }
        },
        file: {
            choose: '',
            upload: ''
        },
        filter: {
            all: '',
            boolean: {
                yes: '',
                no: ''
            },
            clear: '',
            submit: ''
        },
        form: {
            submit: ''
        },
        v2Lock: {
            button: {
                unlock: '',
                unlockWarehouseBackup: '',
                unlocked: ''
            }
        }
    },
    // CORE
    monetary: {
        price: {
            from: '',
            fromPerMonth: '',
            perMonth: '',
            saving: '',
            insuranceUpTo: ''
        },
        label: {
            from: ''
        },
        VAT: {
            included: '',
            notIncluded: ''
        }
    },
    // ADMIN
    AdminWarehouse: {
        active: '',
        entity: {
            attr: {
                code: '',
                name: '',
                coords: '',
                prices: '',
                discounts: '',
                lockDeviceId: ''
            },
            name: '',
            plural: ''
        },
        deactivation: {
            deactivate: '',
            availableFrom: '',
            hint: '',
            cancelDeactivation: ''
        },
        podorys: {
            title: '',
            image: {
                label: '',
                hint: '',
                missing: ''
            },
            geometry: {
                label: '',
                hint: ''
            },
            availableBoxes: '',
            notAvailableBoxes: '',
            preview: '',
            showAccordion: '',
            hideAccordion: ''
        },
        images: {
            title: '',
            add: '',
            edit: '',
            remove: ''
        },
        promo: {
            title: '',
            boxesInPromo: '',
            boxesNotInPromo: '',
            rate: '',
            type: ''
        }
    },
    ServiceAssess: {
        title: '',
        entity: {
            attr: {},
            name: '',
            plural: ''
        }
    },
    EmailEvents: {
        title: '',
        entity: {
            attr: {},
            name: '',
            plural: ''
        }
    },
    // CORE
    CompanyDetails: {
        entity: {
            attr: {
                name: '',
                ico: '',
                dic: '',
                icoDph: '',
                paysDph: ''
            },
            name: ''
        }
    },
    Country: {
        entity: {
            name: ''
        }
    },
    // CORE
    Box: {
        create: '',
        confirmReadiness: {
            title: '',
            confirm: ''
        },
        area: {
            name: '',
            value: '',
            valueWithDimensions: '',
            from: ''
        },
        editInfo: '',
        size: {
            small: {
                name: '',
                value: ''
            },
            medium: {
                name: '',
                value: ''
            },
            large: {
                name: '',
                value: ''
            }
        },
        filter: {
            all: ''
        },
        entity: {
            attr: {
                name: '',
                area: '',
                active: '',
                visible: '',
                waitingForReadinessCheck: ''
            },
            name: '',
            plural: ''
        },
        rented: '',
        overriddenPrice: {
            descriptionText: ''
        }
    },
    Dashboard: {
        statistics: '',
        title: '',
        helperText: ''
    },
    DashboardFilter: {
        entity: {
            attr: {
                interval: ''
            }
        }
    },
    // WEB
    Order: {
        steps: {
            first: {
                title: ''
            },
            second: {
                title: ''
            },
            third: {
                title: '',
                total: '',
                next: '',
                paymentFailedButton: ''
            },
            next: ''
        },
        invoicing: {
            period: {
                one: '',
                three: '',
                six: '',
                twelve: '',
                n: ''
            },
            chooseOption: '',
            selectedOption: ''
        },
        insurance: {
            chooseOption: '',
            selectedOption: ''
        },
        paymentStatus: {
            success: '',
            fail: ''
        }
    },
    // CORE
    Invoice: {
        items: {
            count: '',
            priceWoVat: '',
            subject: '',
            totalPrice: '',
            unitPrice: '',
            vat: ''
        }
    },
    // CORE
    InvoicingDetails: {
        entity: {
            attr: {
                firstName: '',
                lastName: '',
                email: '',
                telephoneNumber: '',
                type: ''
            }
        }
    },
    DashboardPercentageOccupancyLineChart: {
        title: '',
        occupancy: '',
        all: '',
        inactivity: '',
        dailyData: '',
        monthlyData: '',
        selectLargerInterval: ''
    },
    DashboardTrzbyColumnChart: {
        title: '',
        trzby: ''
    },
    DashboardPlatbyColumnChart: {
        title: '',
        payments: ''
    },
    DashboardBoxAvailabilityBarChart: {
        title: '',
        occupied: '',
        free: '',
        inactive: ''
    },
    // CORE
    enum: {
        InvoicingDetails$Type: {
            FyzickaOsoba: '',
            PravnickaOsoba: ''
        },
        ClientUser$Gender: {
            Female: '',
            Male: ''
        },
        Contract$Status: {
            ACTIVE: '',
            TERMINATING: '',
            TERMINATED: ''
        },
        CountryCode: {
            ABW: '',
            AFG: '',
            AGO: '',
            AIA: '',
            ALA: '',
            ALB: '',
            AND: '',
            ARE: '',
            ARG: '',
            ARM: '',
            ASM: '',
            ATA: '',
            ATF: '',
            ATG: '',
            AUS: '',
            AUT: '',
            AZE: '',
            BDI: '',
            BEL: '',
            BEN: '',
            BES: '',
            BFA: '',
            BGD: '',
            BGR: '',
            BHR: '',
            BHS: '',
            BIH: '',
            BLM: '',
            BLR: '',
            BLZ: '',
            BMU: '',
            BOL: '',
            BRA: '',
            BRB: '',
            BRN: '',
            BTN: '',
            BVT: '',
            BWA: '',
            CAF: '',
            CAN: '',
            CCK: '',
            CHE: '',
            CHL: '',
            CHN: '',
            CIV: '',
            CMR: '',
            COD: '',
            COG: '',
            COK: '',
            COL: '',
            COM: '',
            CPV: '',
            CRI: '',
            CUB: '',
            CUW: '',
            CXR: '',
            CYM: '',
            CYP: '',
            CZE: '',
            DEU: '',
            DJI: '',
            DMA: '',
            DNK: '',
            DOM: '',
            DZA: '',
            ECU: '',
            EGY: '',
            ERI: '',
            ESH: '',
            ESP: '',
            EST: '',
            ETH: '',
            FIN: '',
            FJI: '',
            FLK: '',
            FRA: '',
            FRO: '',
            FSM: '',
            GAB: '',
            GBR: '',
            GEO: '',
            GGY: '',
            GHA: '',
            GIB: '',
            GIN: '',
            GLP: '',
            GMB: '',
            GNB: '',
            GNQ: '',
            GRC: '',
            GRD: '',
            GRL: '',
            GTM: '',
            GUF: '',
            GUM: '',
            GUY: '',
            HKG: '',
            HMD: '',
            HND: '',
            HRV: '',
            HTI: '',
            HUN: '',
            IDN: '',
            IMN: '',
            IND: '',
            IOT: '',
            IRL: '',
            IRN: '',
            IRQ: '',
            ISL: '',
            ISR: '',
            ITA: '',
            JAM: '',
            JEY: '',
            JOR: '',
            JPN: '',
            KAZ: '',
            KEN: '',
            KGZ: '',
            KHM: '',
            KIR: '',
            KNA: '',
            KOR: '',
            KWT: '',
            LAO: '',
            LBN: '',
            LBR: '',
            LBY: '',
            LCA: '',
            LIE: '',
            LKA: '',
            LSO: '',
            LTU: '',
            LUX: '',
            LVA: '',
            MAC: '',
            MAF: '',
            MAR: '',
            MCO: '',
            MDA: '',
            MDG: '',
            MDV: '',
            MEX: '',
            MHL: '',
            MKD: '',
            MLI: '',
            MLT: '',
            MMR: '',
            MNE: '',
            MNG: '',
            MNP: '',
            MOZ: '',
            MRT: '',
            MSR: '',
            MTQ: '',
            MUS: '',
            MWI: '',
            MYS: '',
            MYT: '',
            NAM: '',
            NCL: '',
            NER: '',
            NFK: '',
            NGA: '',
            NIC: '',
            NIU: '',
            NLD: '',
            NOR: '',
            NPL: '',
            NRU: '',
            NZL: '',
            OMN: '',
            PAK: '',
            PAN: '',
            PCN: '',
            PER: '',
            PHL: '',
            PLW: '',
            PNG: '',
            POL: '',
            PRI: '',
            PRK: '',
            PRT: '',
            PRY: '',
            PSE: '',
            PYF: '',
            QAT: '',
            REU: '',
            ROU: '',
            RUS: '',
            RWA: '',
            SAU: '',
            SDN: '',
            SEN: '',
            SGP: '',
            SGS: '',
            SHN: '',
            SJM: '',
            SLB: '',
            SLE: '',
            SLV: '',
            SMR: '',
            SOM: '',
            SPM: '',
            SRB: '',
            SSD: '',
            STP: '',
            SUR: '',
            SVK: '',
            SVN: '',
            SWE: '',
            SWZ: '',
            SXM: '',
            SYC: '',
            SYR: '',
            TCA: '',
            TCD: '',
            TGO: '',
            THA: '',
            TJK: '',
            TKL: '',
            TKM: '',
            TLS: '',
            TON: '',
            TTO: '',
            TUN: '',
            TUR: '',
            TUV: '',
            TWN: '',
            TZA: '',
            UGA: '',
            UKR: '',
            UMI: '',
            URY: '',
            USA: '',
            UZB: '',
            VAT: '',
            VCT: '',
            VEN: '',
            VGB: '',
            VIR: '',
            VNM: '',
            VUT: '',
            WLF: '',
            WSM: '',
            YEM: '',
            ZAF: '',
            ZMB: '',
            ZWE: ''
        },
        Box$BoxSize: {
            XS: '',
            SMALL: '',
            MEDIUM: '',
            LARGE: ''
        },
        ReferralRewardTransaction$Type: {
            CREDIT: '',
            DEBIT: ''
        },
        Rent$Status: {
            CREATED: '',
            INVOICE_SUBMITTED: '',
            PAID: ''
        },
        RentPaying$Status: {
            CREATED: '',
            ATTEMPTING_AUTOPAY: '',
            WAITING_FOR_MANUAL: '',
            PAID: ''
        },
        RentPaying$PayMethod: {
            AUTOPAY: '',
            MANUAL: '',
            FROM_ORDER: '',
            MARKED_BY_ADMIN: ''
        },
        ContractCancellationRequest$Reason: {
            PotrebujemPriestoryBlizsie: '',
            NevyhovujeMiCena: '',
            NevyhovujeMiVelkostPriestoru: '',
            UzNepotrebujem: '',
            Ine: ''
        },
        RefundStatus: {
            CREATED: '',
            SUBMITTED: '',
            PROCESSED: ''
        },
        AdminPayment$Type: {
            CANCELED: '',
            INCLUSION: '',
            BANK_TRANSFER: ''
        },
        RentPaying$AdminPaymentType: {
            CANCELED: '',
            INCLUSION: '',
            BANK_TRANSFER: ''
        }
    },
    // WEB
    myZone: {
        myContracts: {
            title: ''
        }
    },
    // CORE
    Contract: {
        entity: {
            attr: {
                createdAt: '',
                terminationAt: '',
                status: ''
            },
            plural: ''
        },
        terminationNotSpecified: '',
        filter: {
            country: {
                label: '',
                placeholder: ''
            },
            status: {
                label: '',
                placeholder: ''
            }
        }
    },
    // CORE
    MyContract: {
        entity: {
            attr: {
                box: '',
                warehouseInfo: {
                    address: '',
                    warehouseCode: ''
                },
                boxDimensions: {
                    size: ''
                },
                refundTotal: '',
                warehouse: '',
                businessId: '',
                createdAt: '',
                terminationAt: '',
                status: '',
                totalPrice: '',
                insurance: {
                    coverage: ''
                },
                accessCode: '',
                lockCode: '',
                invoicingType: '',
                reason: '',
                depositPrice: '',
                depositDeductionAmount: '',
                deductionReason: '',
                boxLockInfo: {
                    lockVersion: '',
                    v1LockCode: ''
                },
                warehouseLockVersion: '',
                overpayment: ''
            }
        },
        terminationNotSpecified: '',
        depositPrice: '',
        accordion: {
            show: '',
            hide: ''
        },
        uploadDocument: {
            title: '',
            file: '',
            submit: '',
            submitSuccess: '',
            remove: ''
        }
    },
    // WEB
    user: {
        attr: {
            username: '',
            password: ''
        }
    },
    // WEB
    loginPage: {
        title: '',
        submit: ''
    },
    // WEB
    registrationPage: {
        title: '',
        submit: ''
    },
    // CORE
    error: {
        unexpected: '',
        'invalid-credentials': '',
        'revalid/rule/basic/not-null': '',
        'revalid/rule/basic/not-empty': '',
        'revalid/rule/basic/regex': '',
        passwordsMustMatch: '',
        mustBeChecked: '',
        'registration/email-exists': '',
        'registration/phone-exists': '',
        'box/not-editable': '',
        'revalid/rule/object/matches-schema': '',
        'length/invalid': '',
        'isJson/invalid': '',
        'podorys/invalidGeometryFormat': '',
        'Warehouse/invalid-geometry-input': '',
        'isGreaterThanZero/invalid': ''
    },
    // ADMIN
    AdminRent: {
        entity: {
            attr: {
                ownerName: '',
                variableSymbol: '',
                paying: {
                    status: '',
                    payMethod: '',
                    adminPaymentType: '',
                    adminPaymentNote: ''
                },
                gatewayMethod: ''
            },
            name: '',
            plural: ''
        },
        markPaid: {
            input: '',
            button: '',
            note: '',
            type: ''
        }
    },
    MyRent: {
        entity: {
            attr: {
                issuedOn: '',
                amount: '',
                paidAt: '',
                status: ''
            },
            invoice: ''
        }
    },
    FranchiseContact: {
        entity: {
            plural: '',
            attr: {
                createdAt: '',
                email: '',
                phoneNumber: ''
            },
            invoice: ''
        }
    },
    ClientUser: {
        entity: {
            name: '',
            plural: '',
            attr: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                gender: ''
            }
        },
        detail: '',
        referral: {
            debitReferral: {
                title: '',
                amount: '',
                submit: '',
                submitSuccess: ''
            }
        }
    },
    AppUser: {
        entity: {
            attr: {
                name: '',
                email: '',
                active: ''
            }
        },
        admin: {
            plural: ''
        },
        owner: {
            plural: ''
        }
    },
    Coordinates: {
        entity: {
            attr: {
                lat: '',
                lng: ''
            }
        }
    },
    Features: {
        entity: {
            attr: {
                barrierFreeEntrance: '',
                nonstopEntry: '',
                heating: '',
                parking: '',
                smart: '',
                videomonitoring: '',
                vouchering: '',
                cooperationWithPlusim: ''
            }
        }
    },
    Accessibility: {
        entity: {
            attr: {
                car: '',
                bus: '',
                trolley: '',
                tram: '',
                metro: ''
            }
        }
    },
    AdminContract: {
        basicData: '',
        contractSubject: '',
        priceData: '',
        invoicingData: '',
        billingData: '',
        companyDetails: '',
        entity: {
            name: ''
        },
        terminationNotSpecified: '',
        invoicingDetails: {
            personal: '',
            nonPersonal: ''
        },
        cancel: {
            title: '',
            acceptCancel: '',
            day: '',
            submit: '',
            submitSuccess: '',
            contractDetail: ''
        },
        eviction: {
            title: '',
            submit: '',
            submitSuccess: ''
        },
        depositDeduction: {
            depositPrice: '',
            amount: '',
            reason: '',
            title: '',
            submit: '',
            submitButtonText: ''
        },
        refund: {
            title: '',
            submit: '',
            submitButtonText: '',
            submitOverpayment: '',
            createdAt: '',
            terminatedAt: '',
            overpayment: '',
            noDepositDeduction: ''
        }
    },
    DayInterval: {
        entity: {
            attr: {
                from: '',
                to: ''
            }
        }
    },
    Tenant: {
        entity: {
            name: '',
            attr: {
                ico: ''
            }
        }
    },
    table: {
        noItems: ''
    },
    nullableEditor: {
        add: '',
        remove: '',
        noValue: ''
    },
    BoxInsurance: {
        entity: {
            attr: {
                coverage: ''
            }
        }
    },
    ClientReferralInfo: {
        entity: {
            attr: {
                url: '',
                referralCount: ''
            }
        }
    },
    ReferralRewardAccountState: {
        entity: {
            attr: {
                balance: '',
                totalCredit: '',
                totalDebit: ''
            }
        }
    },
    AdminReferralRewardTransaction: {
        entity: {
            attr: {
                time: '',
                type: '',
                amount: ''
            },
            plural: ''
        }
    },
    WarehouseInfo: {
        entity: {
            attr: {
                name: '',
                code: ''
            }
        }
    },
    ClientInfo: {
        entity: {
            attr: {
                name: '',
                email: ''
            }
        }
    },
    BoxDimensions: {
        entity: {
            attr: {
                area: '',
                width: '',
                height: '',
                length: ''
            },
            name: ''
        }
    },
    BoxV1Lock: {
        entity: {
            attr: {
                id: '',
                pin: ''
            }
        }
    },
    BoxV2Lock: {
        entity: {
            attr: {
                id: '',
                enabled: '',
                facilityId: ''
            }
        }
    },
    BoxInUseInfo: {
        lock: {
            id: '',
            code: ''
        },
        entity: {
            attr: {
                from: '',
                to: ''
            }
        }
    },
    Owner: {
        create: '',
        entity: {
            name: '',
            plural: '',
            attr: {
                firstName: '',
                lastName: '',
                name: '',
                email: '',
                phoneNumber: ''
            }
        }
    },
    VatPrice: {
        entity: {
            attr: {
                priceWithVat: ''
            }
        }
    },
    model: {
        create: ''
    },
    WarehouseFilter: {
        entity: {
            attr: {
                deactivated: ''
            }
        }
    },
    // Superadmin,
    BrowserErrorReport: {
        entity: {
            attr: {
                id: '',
                error: ''
            }
        }
    },
    BrowserErrorContext: {
        entity: {
            attr: {
                sessionId: '',
                location: '',
                browser: '',
                screenSize: ''
            }
        }
    },
    WarehouseV1Lock: {
        entity: {
            attr: {
                deviceId: ''
            }
        }
    },
    WarehouseV2Lock: {
        entity: {
            attr: {
                id: ''
            }
        }
    },
    RysAuditListItem: {
        entity: {
            attr: {
                businessId: '',
                clazz: '',
                contractId: '',
                coordinatesLat: '',
                coordinatesLng: '',
                createdAt: '',
                exceptionClazz: '',
                exceptionMessage: '',
                facilityId: '',
                id: '',
                isAlert: '',
                lockId: '',
                uri: '',
                userName: ''
            }
        }
    },
    AdminNotificationAuditListItem: {
        entity: {
            attr: {
                createdAt: '',
                emailAddress: '',
                body: '',
                title: ''
            }
        }
    }
}

setPathValues(msg)
