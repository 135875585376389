export const apiOps = {
  AppUser: {
    getAllAdmins: {
      path: '/api/admin/app-user/admins',
      method: 'get'
    },
    getAllClients: {
      path: '/api/admin/app-user/clients',
      method: 'get'
    },
    getAllOwners: {
      path: '/api/admin/app-user/owners',
      method: 'get'
    }
  },
  Box: {
    getAll: {
      path: '/api/admin/box',
      method: 'get'
    },
    create: {
      path: '/api/admin/box',
      method: 'post'
    },
    detail: {
      path: '/api/admin/box/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/admin/box/{id}',
      method: 'put'
    },
    confirmBoxToReady: {
      path: '/api/admin/box/{id}/confirm-box-readiness',
      method: 'post'
    }
  },
  AdminClientUser: {
    readDetail: {
      path: '/api/admin/client-user/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/admin/client-user/{id}',
      method: 'put'
    },
    contracts: {
      path: '/api/admin/client-user/{id}/contracts',
      method: 'get'
    },
    debitFromReferralRewardAccount: {
      path: '/api/admin/client-user/{id}/referral/debit',
      method: 'post'
    },
    clientReferralInfo: {
      path: '/api/admin/client-user/{id}/referral/info',
      method: 'post'
    },
    referralTransactions: {
      path: '/api/admin/client-user/{id}/referral/transactions',
      method: 'get'
    },
    setInvoicingDetails: {
      path: '/api/admin/client-user/{id}/set-invoicing-details',
      method: 'post'
    }
  },
  AdminContract: {
    readDetail: {
      path: '/api/admin/contract/{id}',
      method: 'get'
    },
    cancel: {
      path: '/api/admin/contract/{id}/cancel',
      method: 'post'
    },
    evict: {
      path: '/api/admin/contract/{id}/evict',
      method: 'post'
    },
    getLockAccessPin: {
      path: '/api/admin/contract/{id}/lock-access-pin',
      method: 'get'
    },
    refund: {
      path: '/api/admin/contract/{id}/refund',
      method: 'post'
    },
    rents: {
      path: '/api/admin/contract/{id}/rents',
      method: 'get'
    },
    setDeductionFromDeposit: {
      path: '/api/admin/contract/{id}/set-deduction-from-deposit',
      method: 'post'
    }
  },
  AdminFoo: {
    foo: {
      path: '/api/admin/foo',
      method: 'get'
    }
  },
  AdminFranchising: {
    getAll: {
      path: '/api/admin/franchising',
      method: 'get'
    },
    detail: {
      path: '/api/admin/franchising/{id}',
      method: 'get'
    }
  },
  Owner: {
    getAll: {
      path: '/api/admin/owner',
      method: 'get'
    },
    create: {
      path: '/api/admin/owner',
      method: 'post'
    },
    detail: {
      path: '/api/admin/owner/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/admin/owner/{id}',
      method: 'put'
    },
    contracts: {
      path: '/api/admin/owner/{id}/contracts',
      method: 'get'
    },
    warehouses: {
      path: '/api/admin/owner/{id}/warehouses',
      method: 'get'
    }
  },
  AdminRent: {
    readAllForAdmin: {
      path: '/api/admin/rent',
      method: 'get'
    },
    invoiceDownload: {
      path: '/api/admin/rent/invoice-download/{id}',
      method: 'post'
    },
    readDetailForAdmin: {
      path: '/api/admin/rent/{id}',
      method: 'get'
    },
    markPaid: {
      path: '/api/admin/rent/{id}/mark-paid',
      method: 'post'
    }
  },
  AdminWarehouse: {
    getAll: {
      path: '/api/admin/warehouse',
      method: 'get'
    },
    create: {
      path: '/api/admin/warehouse',
      method: 'post'
    },
    detail: {
      path: '/api/admin/warehouse/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/admin/warehouse/{id}',
      method: 'put'
    },
    boxes: {
      path: '/api/admin/warehouse/{id}/boxes',
      method: 'get'
    },
    contracts: {
      path: '/api/admin/warehouse/{id}/contracts',
      method: 'get'
    },
    deactivate: {
      path: '/api/admin/warehouse/{id}/deactivation',
      method: 'put'
    },
    cancelDeactivation: {
      path: '/api/admin/warehouse/{id}/deactivation',
      method: 'delete'
    }
  },
  AdminWarehousePromo: {
    get: {
      path: '/api/admin/warehouse/promo/{warehouseId}',
      method: 'get'
    },
    set: {
      path: '/api/admin/warehouse/promo/{warehouseId}',
      method: 'put'
    },
    add: {
      path: '/api/admin/warehouse/promo/{warehouseId}/add/{boxId}',
      method: 'put'
    },
    assignableBoxes: {
      path: '/api/admin/warehouse/promo/{warehouseId}/assignable-boxes',
      method: 'get'
    },
    remove: {
      path: '/api/admin/warehouse/promo/{warehouseId}/remove/{boxId}',
      method: 'delete'
    }
  },
  AdminWarehouseImages: {
    getAll: {
      path: '/api/admin/warehouse/{id}/images',
      method: 'get'
    },
    add: {
      path: '/api/admin/warehouse/{id}/images',
      method: 'post'
    },
    change: {
      path: '/api/admin/warehouse/{id}/images/{index}',
      method: 'put'
    },
    delete: {
      path: '/api/admin/warehouse/{id}/images/{index}',
      method: 'delete'
    }
  },
  AdminUserWarehousePodorys: {
    get: {
      path: '/api/admin/warehouse/{id}/podorys',
      method: 'get'
    },
    setGeometry: {
      path: '/api/admin/warehouse/{id}/podorys/geometry',
      method: 'put'
    },
    getGeometryInput: {
      path: '/api/admin/warehouse/{id}/podorys/geometry-input',
      method: 'get'
    },
    setObrazok: {
      path: '/api/admin/warehouse/{id}/podorys/obrazok',
      method: 'put'
    }
  },
  ClientUser: {
    current: {
      path: '/api/client/client-user/current',
      method: 'get'
    },
    register: {
      path: '/api/user/register',
      method: 'post'
    }
  },
  Contract: {
    evict: {
      path: '/api/client/contract/{id}/cancelation/evict',
      method: 'post'
    },
    wasCancellationRequested: {
      path: '/api/client/contract/{id}/cancelation/was-requested',
      method: 'get'
    },
    cancellationOptions: {
      path: '/api/client/contract/{id}/cancellation/first-possible-date',
      method: 'get'
    },
    requestCancellation: {
      path: '/api/client/contract/{id}/cancellation/request',
      method: 'post'
    },
    getLockAccessPin: {
      path: '/api/client/contract/{id}/lock-access-pin',
      method: 'get'
    }
  },
  Franchising: {
    contact: {
      path: '/api/client/franchising/contact',
      method: 'post'
    }
  },
  ClientLockV2: {
    unlockBox: {
      path: '/api/client/lock-v2/box-unlock/{id}',
      method: 'post'
    },
    unlockWarehouse: {
      path: '/api/client/lock-v2/warehouse-unlock/{id}',
      method: 'post'
    }
  },
  ManualPayment: {
    payManually: {
      path: '/api/client/manual-payment/{id}',
      method: 'post'
    }
  },
  MyZone: {
    addBoxSharing: {
      path: '/api/client/my/add-box-sharing',
      method: 'post'
    },
    changePassword: {
      path: '/api/client/my/change-password',
      method: 'post'
    },
    contractFirstAt: {
      path: '/api/client/my/contract/firstAt',
      method: 'get'
    },
    contracts: {
      path: '/api/client/my/contracts',
      method: 'get'
    },
    contractDetail: {
      path: '/api/client/my/contracts/{id}',
      method: 'get'
    },
    contractRents: {
      path: '/api/client/my/contracts/{id}/rents',
      method: 'get'
    },
    invoiceDownload: {
      path: '/api/client/my/invoice-download/{id}',
      method: 'get'
    },
    isSubscribedToNewsletter: {
      path: '/api/client/my/is-subscribed-to-newsletter',
      method: 'get'
    },
    legalAgreementDownload: {
      path: '/api/client/my/legal-agreement-download/{id}',
      method: 'get'
    },
    getProfile: {
      path: '/api/client/my/profile',
      method: 'get'
    },
    updateProfile: {
      path: '/api/client/my/profile',
      method: 'put'
    },
    referralInfo: {
      path: '/api/client/my/referral-info',
      method: 'get'
    },
    removeBoxSharing: {
      path: '/api/client/my/remove-box-sharing',
      method: 'post'
    },
    rents: {
      path: '/api/client/my/rents',
      method: 'get'
    },
    setInvoicingDetails: {
      path: '/api/client/my/set-invoicing-details',
      method: 'post'
    },
    setLocale: {
      path: '/api/client/my/set-locale',
      method: 'post'
    },
    sharedBoxes: {
      path: '/api/client/my/shared-boxes',
      method: 'get'
    },
    subscribeToNewsletter: {
      path: '/api/client/my/subscribe-to-newsletter',
      method: 'post'
    }
  },
  Order: {
    summary: {
      path: '/api/client/order/offer/summary',
      method: 'put'
    },
    verifyAutoregistrationInvoicingDetails: {
      path: '/api/client/order/offer/verify-autoregistration-invoicing-details',
      method: 'put'
    },
    offer: {
      path: '/api/client/order/offer/{boxId}',
      method: 'get'
    },
    placeWithLogin: {
      path: '/api/client/order/place/with-login',
      method: 'post'
    },
    placeWithoutLogin: {
      path: '/api/client/order/place/without-login',
      method: 'post'
    },
    status: {
      path: '/api/client/order/status',
      method: 'get'
    }
  },
  Warehouse: {
    getAll: {
      path: '/api/client/warehouse',
      method: 'get'
    },
    around: {
      path: '/api/client/warehouse/around',
      method: 'get'
    },
    readDetail: {
      path: '/api/client/warehouse/{id}',
      method: 'get'
    },
    boxes: {
      path: '/api/client/warehouse/{id}/boxes',
      method: 'get'
    },
    podorys: {
      path: '/api/client/warehouse/{id}/podorys',
      method: 'get'
    },
    recommended: {
      path: '/api/client/warehouse/{id}/recommended',
      method: 'get'
    },
    subscribeToNewFreeBox: {
      path: '/api/client/warehouse/{id}/subscribe/new-free-box',
      method: 'put'
    }
  },
  CommonAdminContract: {
    invoicingTypes: {
      path: '/api/common/contract/invoicing-types',
      method: 'get'
    },
    legalAgreementDownload: {
      path: '/api/common/contract/legal-agreement-download/{id}',
      method: 'post'
    },
    add: {
      path: '/api/common/contract/{id}/other-documents/add',
      method: 'post'
    },
    getAll: {
      path: '/api/common/contract/{id}/other-documents/all',
      method: 'get'
    },
    delete: {
      path: '/api/common/contract/{id}/other-documents/delete/{index}',
      method: 'delete'
    }
  },
  Dashboard: {
    get: {
      path: '/api/common/dashboard',
      method: 'get'
    },
    getPodorys: {
      path: '/api/common/dashboard/podorys/{id}',
      method: 'get'
    },
    availableWarehouses: {
      path: '/api/common/dashboard/warehouses',
      method: 'get'
    }
  },
  Export: {
    get: {
      path: '/api/common/export',
      method: 'get'
    }
  },
  AdminCommonLockV2: {
    unlockBox: {
      path: '/api/common/lock-v2/box-unlock/{id}',
      method: 'post'
    },
    GetStates: {
      path: '/api/common/lock-v2/states',
      method: 'get'
    },
    unlockWarehouse: {
      path: '/api/common/lock-v2/warehouse-unlock/{id}',
      method: 'post'
    },
    unlockBackupWarehouse: {
      path: '/api/client/lock-v2/warehouse-unlock-backup/{id}',
      method: 'post'
    }
  },
  AdminCommonUnlockToken: {
    unlockTokens: {
      path: '/api/common/unlock-token',
      method: 'get'
    },
    generateUnlockToken: {
      path: '/api/common/unlock-token/generate-unlock-token',
      method: 'post'
    }
  },
  ErrorReporting: {
    reportError: {
      path: '/api/ops/error',
      method: 'post'
    }
  },
  OwnerUserBox: {
    create: {
      path: '/api/owner/box',
      method: 'post'
    },
    detail: {
      path: '/api/owner/box/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/owner/box/{id}',
      method: 'put'
    }
  },
  OwnerContract: {
    contract: {
      path: '/api/owner/contract/{id}',
      method: 'get'
    },
    cancel: {
      path: '/api/owner/contract/{id}/cancel',
      method: 'post'
    },
    evict: {
      path: '/api/owner/contract/{id}/evict',
      method: 'post'
    },
    getLockAccessPin: {
      path: '/api/owner/contract/{id}/lock-access-pin',
      method: 'get'
    },
    rents: {
      path: '/api/owner/contract/{id}/rents',
      method: 'get'
    }
  },
  OwnerRent: {
    readAllForOwner: {
      path: '/api/owner/rent',
      method: 'get'
    },
    readDetailForOwner: {
      path: '/api/owner/rent/detail/{id}',
      method: 'get'
    },
    invoiceDownloadForOwner: {
      path: '/api/owner/rent/invoice-download/{id}',
      method: 'post'
    },
    markPaid: {
      path: '/api/owner/rent/{id}/mark-paid',
      method: 'post'
    }
  },
  OwnerUserWarehouse: {
    getAll: {
      path: '/api/owner/warehouse',
      method: 'get'
    },
    detail: {
      path: '/api/owner/warehouse/{id}',
      method: 'get'
    },
    edit: {
      path: '/api/owner/warehouse/{id}',
      method: 'put'
    },
    boxes: {
      path: '/api/owner/warehouse/{id}/boxes',
      method: 'get'
    },
    contracts: {
      path: '/api/owner/warehouse/{id}/contracts',
      method: 'get'
    },
    deactivate: {
      path: '/api/owner/warehouse/{id}/deactivation',
      method: 'put'
    },
    cancelDeactivation: {
      path: '/api/owner/warehouse/{id}/deactivation',
      method: 'delete'
    }
  },
  OwnerUserWarehouseImages: {
    getAll: {
      path: '/api/owner/warehouse/{id}/images',
      method: 'get'
    },
    add: {
      path: '/api/owner/warehouse/{id}/images',
      method: 'post'
    },
    change: {
      path: '/api/owner/warehouse/{id}/images/{index}',
      method: 'put'
    },
    delete: {
      path: '/api/owner/warehouse/{id}/images/{index}',
      method: 'delete'
    }
  },
  OwnerUserWarehousePodorys: {
    get: {
      path: '/api/owner/warehouse/{id}/podorys',
      method: 'get'
    },
    setGeometry: {
      path: '/api/owner/warehouse/{id}/podorys/geometry',
      method: 'put'
    },
    getGeometryInput: {
      path: '/api/owner/warehouse/{id}/podorys/geometry-input',
      method: 'get'
    },
    setObrazok: {
      path: '/api/owner/warehouse/{id}/podorys/obrazok',
      method: 'put'
    }
  },
  Dev: {
    cancelContract: {
      path: '/api/superadmin/__dev/cancel-contract/{contractId}',
      method: 'post'
    }
  },
  SuperadminAdminNotificationAudit: {
    adminNotificationAudits: {
      path: '/api/superadmin/admin-notification-audit',
      method: 'get'
    }
  },
  Documents: {
    all: {
      path: '/api/superadmin/document',
      method: 'get'
    },
    downloadLink: {
      path: '/api/superadmin/document/download-link/{id}',
      method: 'post'
    }
  },
  SuperAdminErrorReporting: {
    errors: {
      path: '/api/superadmin/error-reporting',
      method: 'get'
    }
  },
  Jobs: {
    runList: {
      path: '/api/superadmin/jobs/runs',
      method: 'get'
    },
    runDetail: {
      path: '/api/superadmin/jobs/runs/{id}',
      method: 'get'
    },
    startJob: {
      path: '/api/superadmin/jobs/start/{job}',
      method: 'post'
    }
  },
  SuperadminOrders: {
    list: {
      path: '/api/superadmin/order',
      method: 'get'
    },
    cancel: {
      path: '/api/superadmin/order/{id}/cancel',
      method: 'post'
    },
    processManual: {
      path: '/api/superadmin/order/{id}/process-manual',
      method: 'post'
    }
  },
  SuperadminRent: {
    waitingForManual: {
      path: '/api/superadmin/rent',
      method: 'get'
    }
  },
  SuperadminRysAudit: {
    rysAudits: {
      path: '/api/superadmin/rys-audit',
      method: 'get'
    }
  },
  SuperAdminUserWarehouseV2Lock: {
    setFacilityId: {
      path: '/api/superadmin/warehouse/lock-v2/set-facility-id/{id}',
      method: 'put'
    },
    setV2Lock: {
      path: '/api/superadmin/warehouse/lock-v2/set-v2-lock/{id}',
      method: 'put'
    }
  },
  SuperadminWarehouse: {
    delete: {
      path: '/api/superadmin/warehouse/{id}',
      method: 'delete'
    }
  },
  RysAuditListItem: {
    list: {
      path: '/api/superadmin/rys-audit',
      method: 'get'
    }
  }
}
